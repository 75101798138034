<template>
  <div class="kapitus-note mt-4 p-0">
    <p>* This amount does not reflect the amount paid or the amount owed as a result of chargebacks to the referral provider pursuant to your agreement with Kapitus.</p>
  </div>
</template>

<script>
export default {
  name: 'CommissionNote',
  data() {
    return {
    }
  },
}
</script>
